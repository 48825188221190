export const environment = {
  appId: 'dreampay-linkgenerator',
  env: 'prod',
  name: 'LINK_GENERATOR',
  production: true,
  checkoutLink: false,
  hmr: false,
  firebaseConfig: {
    apiKey: 'AIzaSyDqfYQSqdT7V-yEmLDR2S57pEEU17VqLqM',
    authDomain: 'link-generator-807df.firebaseapp.com',
    projectId: 'link-generator-807df',
    storageBucket: 'link-generator-807df.appspot.com',
    messagingSenderId: '926945547481',
    appId: '1:926945547481:web:e3888f893f3a7729be9ac8',
    measurementId: 'G-2CHJBKWZ4N',
    locationId: 'europe-west3'
  },
  apiURL: 'https://private-api.dreampay.com.br/api/',
  API_BASE_PATH: 'https://private-api.dreampay.com.br',
  enableComplianceForm: false,
  paymentLinkBaseUrl: 'https://payment.dreampay.com.br/pay/'
};
